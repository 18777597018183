import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { baseUrl } from '../../constant/constant';

const EmployList = () => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const { isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    const fetchEmployees = async () => {
      if (!isAuthenticated) {
        toast.error('You need to be logged in to view this page.', {
          position: 'top-right',
        });
        return;
      }

      try {
        const token = localStorage.getItem('token');
        const response = await fetch(baseUrl + 'getAllEmploys', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setEmployees(data.users);
        } else {
          const data = await response.json();
          toast.error(data.message || 'Failed to fetch employees.', {
            position: 'top-right',
          });
        }
      } catch (error) {
        toast.error('An error occurred while fetching employees.', {
          position: 'top-right',
        });
      } finally {
        setLoading(false);
      }
    };

    fetchEmployees();
  }, [isAuthenticated]);

  const deleteEmploy = async (id) => {
    const token = localStorage.getItem('token');

    try {
      const response = await fetch(`${baseUrl}deleteEmploy`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ id }),
      });

      if (response.ok) {
        setEmployees((prevEmployees) => prevEmployees.filter((employee) => employee.id !== id));
        toast.success('Employee deleted successfully!', {
          position: 'top-right',
        });
      } else {
        const data = await response.json();
        toast.error(data.message || 'Failed to delete employee.', {
          position: 'top-right',
        });
      }
    } catch (error) {
      toast.error('An error occurred while deleting the employee.', {
        position: 'top-right',
      });
    }
  };

  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold mb-6">Employee List</h1>
      {loading ? (
        <p>Loading...</p>
      ) : employees.length > 0 ? (
        <table className="w-full bg-white border border-gray-300 rounded-md shadow-md">
          <thead>
            <tr className="bg-gray-100 border-b">
              <th className="p-4 text-left">ID</th>
              <th className="p-4 text-left">Name</th>
              <th className="p-4 text-left">Email</th>
              <th className="p-4 text-left">Created At</th>
              <th className="p-4 text-left">Updated At</th>
              <th className="p-4 text-left">Action</th>
            </tr>
          </thead>
          <tbody>
            {employees.map((employee) => (
              <tr key={employee.id} className="border-b">
                <td className="p-4">{employee.id}</td>
                <td className="p-4">{employee.name}</td>
                <td className="p-4">{employee.email}</td>
                <td className="p-4">{new Date(employee.created_at).toLocaleString()}</td>
                <td className="p-4">{new Date(employee.updated_at).toLocaleString()}</td>
                <td className="p-4">
                  <button
                    onClick={() => deleteEmploy(employee.id)}
                    className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="w-full p-8 text-center bg-gray-100 border border-gray-300 rounded-md shadow-md">
          <p className="text-xl text-gray-600">No employees found.</p>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default EmployList;
